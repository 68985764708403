<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-tab">
          <span class="popup-item popup-active">1床 孙莹莹</span>
          <span class="popup-item">2床 李慧慧</span>
          <span class="popup-item">卫生间</span>
        </div>
        <div class="popup-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="popup-content">
        <div class="popup-left">
          <div class="popup-head">
            <span>睡觉</span>
            <span class="text">（实时监测中）</span>
            <span class="label">在床</span>
            <span class="label">离床</span>
          </div>
          <div class="popup-speed">
            <span>98% 稳定</span>
          </div>
          <div class="popup-chart" id="chart1"></div>
          <div class="popup-chart" id="chart2"></div>
        </div>
        <div class="popup-right">
          <div class="popup-list">
            <div class="popup-head">
              <span class="text">监测仪</span>
              <el-switch v-model="value" active-text="开" inactive-text="关">
              </el-switch>
            </div>
            <div class="slider">
              <el-slider v-model="slider" :max="30" :show-tooltip="true" :marks="marks"></el-slider>
            </div>
          </div>
          <div class="popup-list">
            <img src="../../../../assets/img/fei.png" alt="" class="popup-img">
            <div class="slider">
              <el-slider v-model="slider" :max="30" :show-tooltip="true" :marks="marks"></el-slider>
            </div>
          </div>
          <div class="popup-list">
            <img src="../../../../assets/img/xin.png" alt="" class="popup-img">
            <div class="slider">
              <el-slider v-model="slider" :max="30" :show-tooltip="true" :marks="marks"></el-slider>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-footer">
        <div class="info active">
          <span>提供用户基本信息数据会更加精准</span>
        </div>
        <div class="info">
          <span>查看报告</span>
        </div>
        <div class="date">
          <div class="date-item">
            <span>开始监测</span>
            <span>结束监测</span>
          </div>
          <div class="date-item">
            <span>23:00</span>
            <span class="el-icon-right"></span>
            <span>05:30</span>
          </div>
        </div>
        <div class="news">
          <span class="title">历史信息</span>
          <span class="text">2024年05月06日 15:00:00 监测仪恢复正常</span>
          <span class="text">2024年05月06日 15:00:00 监测仪恢复正常</span>
          <span class="text">2024年05月06日 15:00:00 监测仪恢复正常</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      infos: [],
      value: true,
      slider: 15,
      marks: {
        0: '偏低',
        15: '正常'
      },
      chart1: null,
      chart2: null,
      params: {
        // sbbh: '',
        // ylbh: '',
        // jczt: '',
        // sfzh: '',
        // name: '',
        // xb: '',
        // age: ''
        imei: '869840066028837',
        numberOne: 0
      },
      chartsData: [],
      xls: [],
      hxsY: [],
      hxsX: [],
      data: [],
      stop: 0,
      index: 0
    }
  },
  mounted () {
    this.generateData()
    this.setInterval = setInterval(() => {
      this.getInfo()
    }, 6000)
  },
  beforeDestroy () {
    clearInterval(this.setInterval)
    this.setInterval = null
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-178092378
      try {
        this.params.numberOne++
        const res = await this.$http.post('/zhyyapi/findssjcjl', this.params)
        console.log('监测数据实时记录', res.data)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        const arr = res.data
        for (let index = 0; index < arr.length; index++) {
          this.hxsX.push(this.hxsX[this.hxsX.length - 1] + 1)
          this.hxsY.push(arr[index].respiration || 0)
          this.hxsX.shift()
          this.hxsY.shift()

          this.xls.push([this.xls[this.xls.length - 1][0] + 1, arr[index].heartRate || 0])
          for (let index = 0; index < this.xls.length; index++) {
            this.xls[index][0] = this.xls[index][0] - 1
          }
          this.xls.shift()
        }
        this.chart1.setOption({
          xAxis: {
            data: this.hxsX
          },
          series: [
            {
              data: this.hxsY
            }
          ]
        })
        this.chart2.setOption({
          series: [
            {
              data: this.xls
            }
          ]
        })
      } catch (error) {
        console.log('监测数据实时记录', error)
      }
    },
    generateData () {
      for (let i = 0; i <= 400; i++) {
        this.xls.push([i, 0])
      }
      for (let i = 0; i <= 100; i++) {
        this.hxsX.push(i)
        this.hxsY.push(0)
      }
      this.canvas1()
      this.canvas2()
    },
    tabClick (index) {
      this.tabIndex = index
    },
    canvas1 () {
      if (!this.chart1) {
        var chartDom = document.getElementById('chart1')
        this.chart1 = this.$echarts.init(chartDom)
      }
      const option = {
        grid: {
          containLabel: true,
          left: '0',
          top: '10%',
          right: '2%',
          bottom: '2%'
        },
        xAxis: {
          show: false,
          type: 'category',
          data: this.hxsX
        },
        yAxis: {
          show: false,
          type: 'value'
        },
        series: [
          {
            data: this.hxsY,
            type: 'line',
            smooth: true,
            showSymbol: false
          }
        ]
      }

      option && this.chart1.setOption(option)
    },
    canvas2 () {
      if (!this.chart2) {
        var chartDom = document.getElementById('chart2')
        this.chart2 = this.$echarts.init(chartDom)
      }
      // const option = {
      //   grid: {
      //     containLabel: true,
      //     left: '0',
      //     top: '10%',
      //     right: '2%',
      //     bottom: '2%'
      //   },
      //   xAxis: {
      //     show: false,
      //     data: this.xls.map(function (item) {
      //       return item[0]
      //     }),
      //     axisLabel: {
      //       show: true,
      //       color: '#F1F7FF',
      //       fontSize: 13
      //     },
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: '#F1F7FF'
      //       }
      //     },
      //     splitLine: {
      //       lineStyle: {
      //         color: '#F1F7FF'
      //       }
      //     }
      //   },
      //   yAxis: {
      //     show: false,
      //     axisLabel: {
      //       show: true,
      //       color: '#F1F7FF',
      //       fontSize: 13
      //     },
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: '#F1F7FF'
      //       }
      //     },
      //     splitLine: {
      //       lineStyle: {
      //         color: '#F1F7FF'
      //       }
      //     }
      //   },
      //   series: {
      //     name: 'Beijing AQI',
      //     type: 'line',
      //     data: this.xls.map(function (item) {
      //       return item[1]
      //     }),
      //     showSymbol: false
      //   }
      // }
      const option = {
        animation: true,
        grid: {
          containLabel: true,
          left: '0',
          top: '10%',
          right: '2%',
          bottom: '2%'
        },
        xAxis: {
          show: false,
          name: 'x',
          minorTick: {
            show: false
          },
          minorSplitLine: {
            show: false
          }
        },
        yAxis: {
          show: false,
          max: 250,
          name: 'y',
          minorTick: {
            show: false
          },
          minorSplitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'line',
            showSymbol: false,
            clip: true,
            data: this.xls
          }
        ]
      }

      option && this.chart2.setOption(option)
    },
    close () {
      this.$parent.show = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .83);

  .popup-box {
    width: 90%;
    height: 90%;
    padding: 0 16px;
    background-color: #F1F7FF;
    border-radius: 6px;
    box-sizing: border-box;

    .popup-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 70px;

      .popup-tab {
        display: flex;
        flex-direction: row;
        align-items: center;

        .popup-item {
          width: 150px;
          height: 30px;
          line-height: 30px;
          margin-right: 20px;
          text-align: center;
          border-radius: 2px;
          background-color: #969CA3;
          color: #fff;
          font-size: 20px;
        }

        .popup-active {
          background-color: #006EFF;
        }
      }

      .popup-close {
        color: #B0B0B0;
        font-size: 34px;
        cursor: pointer;
      }
    }

    .popup-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100% - 240px);
      padding-bottom: 46px;
      box-sizing: border-box;

      .popup-left {
        width: 65%;
        height: 100%;
        border-right: 2px dashed #999999;
        box-sizing: border-box;

        .popup-head {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 20px;
          box-sizing: border-box;
          color: #006EFF;
          font-size: 16px;

          .text {
            margin-left: 20px;
          }

          .label {
            margin-right: 20px;
            padding: 2px 24px;
            border-radius: 2px;
            background-color: #969CA3;
            color: #fff;
            font-size: 16px
          }
        }

        .popup-speed {
          width: calc(100% - 69px);
          height: 20px;
          line-height: 20px;
          margin-top: 2px;
          padding-left: 18px;
          background: linear-gradient(90deg, #E29513 0%, #7FB92E 100%);
          box-sizing: border-box;
          color: #fff;
          font-size: 14px;
        }

        .popup-chart {
          position: relative;
          width: 100%;
          height: calc(50% - 50px);
        }
      }

      .popup-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 35%;
        height: 100%;
        padding-left: 84px;
        padding-right: 59px;
        box-sizing: border-box;

        .popup-head {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          color: #006EFF;
          font-size: 16px;

          .text {
            margin-right: 30px;
          }

          /deep/.el-switch__label {
            position: absolute;
            display: none;
            color: #fff;
          }

          /*打开时文字位置设置*/
          /deep/.el-switch__label--right {
            z-index: 1;
            left: 2px;
            /*不同场景下可能不同，自行调整*/
          }

          /*关闭时文字位置设置*/
          /deep/.el-switch__label--left {
            z-index: 1;
            right: 2px;
            /*不同场景下可能不同，自行调整*/
          }

          /*显示文字*/
          /deep/.el-switch__label.is-active {
            display: block;
          }

          /deep/.el-switch .el-switch__core,
          .el-switch .el-switch__label {
            width: 60px !important;
            /*开关按钮的宽度大小*/
          }
        }

        .popup-list {
          display: flex;
          flex-direction: column;
          align-items: center;

          .popup-img {
            width: 74px;
            height: 56px;
          }

          .slider {
            width: 100%;

            /deep/.el-slider__marks-text:first-child {
              margin-left: 16px;
            }
          }
        }
      }
    }

    .popup-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 170px;
      border-top: 2px dashed #999999;
      box-sizing: border-box;

      .info {
        width: calc(100% / 6 - 10px);
        height: 96px;
        line-height: 96px;
        text-align: center;
        background-color: #BCD1EC;
        border-radius: 6px;
        color: #006EFF;
        font-size: 16px;
      }

      .active {
        background-color: #84ADE3;
      }

      .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: calc(100% / 6 - 10px);
        height: 96px;
        padding: 0 28px;
        border-radius: 6px;
        background-color: #BCD1EC;
        box-sizing: border-box;

        .date-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #006EFF;
          font-size: 16px;
        }

        .date-item:last-child {
          padding: 0 10px;
          box-sizing: border-box;
        }
      }

      .news {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 50%;
        height: 96px;
        padding: 0 28px;
        border-radius: 6px;
        background-color: #BCD1EC;
        box-sizing: border-box;

        .title {
          color: #006EFF;
          font-size: 16px;
        }

        .text {
          color: #006EFF;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
